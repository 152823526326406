export default [
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    route: 'admin-dashboard',
    component: () => import('@/views/admin/dashboard'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  },
  {
    path: '/admin/clients',
    name: 'client-list',
    route: 'client-list',
    component: () => import('@/views/admin/client/ClientList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  },
  {
    path: '/admin/add-clients',
    name: 'add-client',
    route: 'add-client',
    component: () => import('@/views/admin/client/AddClient'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved', 
    navActiveLink: 'client-list' }
  },
  {
    path: '/admin/edit-clients/:id',
    name: 'edit-client',
    route: 'edit-client',
    component: () => import('@/views/admin/client/EditClient'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved', 
    navActiveLink: 'client-list' }
  },
  {
    path: '/admin/view-clients-users/:id',
    name: 'view-client-users',
    route: 'view-client-users',
    component: () => import('@/views/admin/client/UserList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved', 
    navActiveLink: 'client-list' }
  },
  {
    path: '/admin/add-client-user/:id',
    name: 'admin-add-client-user',
    route: 'admin-add-client-user',
    component: () => import('@/views/admin/users/AddUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'client-list'}
  },
  {
    path: '/admin/edit-client-user/:id/:organisation?',
    name: 'admin-edit-client-user',
    route: 'admin-edit-client-user',
    component: () => import('@/views/admin/users/EditUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'client-list'}
  },
  {
    path: '/admin/duplicate-client-user/:id/:organisation?',
    name: 'admin-duplicate-client-user',
    route: 'admin-duplicate-client-user',
    component: () => import('@/views/admin/users/DuplicateUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'client-list'}
  },
  {
    path: '/admin/email-templates',
    name: 'email-list',
    component: () => import('@/views/admin/emailtemplate/List.vue'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin' , accountStatus:'approved'}
  },
  {
    path: '/admin/email-templates/:id',
    name: 'edit-email',
    component: () => import('@/views/admin/emailtemplate/Edit.vue'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin' , accountStatus:'approved',navActiveLink: 'email-list'}
  },
  {
    path: '/admin/job-titles',
    name: 'job-title',
    route: 'job-title',
    component: () => import('@/views/admin/job_title/JobTitleList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  },
  {
    path: '/admin/add-job-title',
    name: 'add-job-title',
    route: 'add-job-title',
    component: () => import('@/views/admin/job_title/AddJobTitle'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'job-title'}
  },
  {
    path: '/admin/edit-job-title/:id',
    name: 'edit-job-title',
    route: 'edit-job-title',
    component: () => import('@/views/admin/job_title/EditJobTitle'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'job-title'}
  },
  {
    path: '/admin/locations',
    name: 'location',
    route: 'location',
    component: () => import('@/views/admin/location/index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  },
  {
    path: '/admin/add-location',
    name: 'add-location',
    route: 'add-location',
    component: () => import('@/views/admin/location/add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'location'}
  },
  {
    path: '/admin/edit-location/:id',
    name: 'edit-location',
    route: 'edit-location',
    component: () => import('@/views/admin/location/edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'location'}
  },
  {
    path: '/admin/setting/general',
    name: 'general-setting',
    component: () => import('@/views/admin/setting/GeneralSetting.vue'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin' , accountStatus:'approved'}
  },
  {
    path: '/admin/setting/default',
    name: 'default-setting',
    component: () => import('@/views/admin/setting/DefaultSetting.vue'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin' , accountStatus:'approved'}
  },
  {
    path: '/admin/setting/app',
    name: 'app-setting',
    component: () => import('@/views/admin/setting/AppSetting.vue'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin' , accountStatus:'approved'}
  },
  {
    path: '/admin/setting/edit-general-settings',
    name: 'edit-general-setting',
    component: () => import('@/views/admin/setting/EditGeneralSettings.vue'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', navActiveLink: 'general-setting' , accountStatus:'approved'}
  },
  {
    path: '/admin/setting/edit-default-settings',
    name: 'edit-default-setting',
    component: () => import('@/views/admin/setting/EditDefaultSettings.vue'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', navActiveLink: 'default-setting' , accountStatus:'approved'}
  },
  {
    path: '/admin/setting/edit-app-settings',
    name: 'edit-app-setting',
    component: () => import('@/views/admin/setting/EditAppSettings.vue'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', navActiveLink: 'default-setting' , accountStatus:'approved'}
  },
  {
    path: '/admin/holiday',
    name: 'admin-holiday',
    route: 'admin-holiday',
    component: () => import('@/views/admin/holiday/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  },
  {
    path: '/admin/feedback-panel-config',
    name: 'feedback-panel-config',
    route: 'feedback-panel-config',
    component: () => import('@/views/admin/panel_data/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  },
  {
    path: '/admin/feedback-panel-config/edit/:id',
    name: 'edit-feedback-panel-config',
    route: 'edit-feedback-panel-config',
    component: () => import('@/views/admin/panel_data/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'feedback-panel-config'}
  },
  {
    path: '/admin/feedback-panel-backgrounds',
    name: 'master-backgrounds',
    route: 'master-backgrounds',
    component: () => import('@/views/admin/panel_data/Background'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  },
  {
    path: '/admin/attendance-status',
    name: 'attendance-status',
    route: 'attendance-status',
    component: () => import('@/views/admin/attendance_status/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  },
  {
    path: '/admin/add-attendance-status',
    name: 'add-attendance-status',
    route: 'add-attendance-status',
    component: () => import('@/views/admin/attendance_status/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'attendance-status'}
  },
  {
    path: '/admin/edit-attendance-status/:id',
    name: 'edit-attendance-status',
    route: 'edit-attendance-status',
    component: () => import('@/views/admin/attendance_status/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'attendance-status'}
  },
  {
    path: '/admin/payroll-paid',
    name: 'payroll-paid',
    route: 'payroll-paid',
    component: () => import('@/views/admin/payroll_companies/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  },
  {
    path: '/admin/add-payroll-paid',
    name: 'add-payroll-paid',
    route: 'add-payroll-paid',
    component: () => import('@/views/admin/payroll_companies/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'payroll-paid'}
  },
  {
    path: '/admin/edit-payroll-paid/:id',
    name: 'edit-payroll-paid',
    route: 'edit-payroll-paid',
    component: () => import('@/views/admin/payroll_companies/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'payroll-paid'}
  }, {
    path: '/admin/role-permission',
    name: 'role-menu',
    route: 'role-menu',
    component: () => import('@/views/admin/roleMenu/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  }, {
    path: '/admin/add-role-permission',
    name: 'add-role-menu',
    route: 'add-role-menu',
    component: () => import('@/views/admin/roleMenu/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'role-menu'}
  }, {
    path: '/admin/edit-role-permission/:id',
    name: 'edit-role-menu',
    route: 'edit-role-menu',
    component: () => import('@/views/admin/roleMenu/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'role-menu'}
  }, {
    path: '/admin/broadcast-message',
    name: 'broadcast-message',
    route: 'broadcast-message',
    component: () => import('@/views/admin/broadcastMessage/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' , 
    navActiveLink: 'broadcast-message'}
  }, {
    path: '/admin/change-password',
    name: 'admin-change-password',
    route: 'admin-change-password',
    component: () => import('@/views/admin/ChangePassword'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'admin', accountStatus:'approved' }
  },
]
